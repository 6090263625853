import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { Text } from '@mantine/core'
import { MoonLoader } from 'common/components'
import {
  ISendConfigMessage,
  useLoadClientAssets,
  useMessageActions,
  useSelector,
} from 'common/hooks'
import { loadStaticFromPublic } from 'common/utils'
import { t } from 'i18next'
import { useIFrameContext } from 'iframe/hooks'

const HEADER_HEIGHT = 50

interface IProps {
  url: string
}

export default function IFrame({ url }: IProps) {
  const { pathname, hash, search, state } = useLocation()
  const iframeRef = useIFrameContext()
  const { title } = useSelector((state) => state.iframe)
  const { sendConfigMessage, sendApprovalCloseDetailsMessage } = useMessageActions()
  const { theme } = useLoadClientAssets()
  const [isAppReady, setIsAppReady] = useState(false)

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - HEADER_HEIGHT,
  })

  const path = useMemo(() => pathname || url, [pathname, url])
  const iframeUrl = useMemo(() => localStorage.getItem('application_url') || '', [])
  const applicationName = useMemo(
    () => localStorage.getItem('application_name') || '',
    [],
  )

  const handleFrameLoaded = useCallback(() => {
    const hostname = window.location.hostname
    const originUrl = window.location.origin
    const parts = hostname.split('.')
    const isHostnameLocalhostOrDev =
      hostname === 'localhost' ||
      hostname.includes('localhost') ||
      hostname.includes('app-dev') ||
      hostname.includes('app-stag') ||
      hostname.includes('app-prod') ||
      hostname.includes('app.')
    const subdomainName = parts[0] === 'www' ? parts[1] : parts[0]

    const payload: ISendConfigMessage = {
      originUrl,
      accessToken: localStorage.getItem('access_token') || '',
      cssFiles: [
        `${originUrl}${loadStaticFromPublic('css/common.css')}`,
        `${originUrl}${loadStaticFromPublic(
          `css/${isHostnameLocalhostOrDev ? 'xelto' : subdomainName}.theme.css`,
        )}`,
      ],
      theme,
    }

    setTimeout(() => {
      sendConfigMessage(payload)
      setIsAppReady(true)
    }, 250)
  }, [sendConfigMessage, theme])

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      const height = window.innerHeight - HEADER_HEIGHT
      setSize({ width, height })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', () => {
      sendApprovalCloseDetailsMessage({ state })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!isAppReady && (
        <div
          className={
            'flex justify-center items-center gap-1 p-4 absolute w-full h-[calc(100%-60px)] bottom-0 left-0 bg-white z-[99]'
          }
        >
          <MoonLoader
            text={
              <div className={'flex items-center gap-1'}>
                <Text>{t('common.initialization')}</Text>
                <Text className={'font-semibold'}>
                  {t(`apps.${applicationName.toLowerCase().replace(/ /g, '-')}`)}
                </Text>
              </div>
            }
          />
        </div>
      )}
      <iframe
        className={'border-0'}
        height={size.height}
        key={path}
        onLoad={handleFrameLoaded}
        ref={iframeRef}
        src={iframeUrl + path + hash + search}
        width={size.width}
      />
    </>
  )
}
