import { forwardRef, Ref, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Accordion, Avatar, Divider, Menu, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
  IconAtom,
  IconAtom2,
  IconBuilding,
  IconBuildings,
  IconChevronDown,
  IconLogin2,
  IconSitemap,
  IconUser,
  IconX,
} from '@tabler/icons-react'
import { useSetUserMutation } from 'auth/api'
import { AuthLoginStep, IAuthorizedUser, TJwtPayload } from 'auth/models'
import {
  clearUserApplicationid,
  clearUserClientId,
  clearUserSiteId,
  setInitialAuthState,
  setLoginStep,
  setUser,
} from 'auth/store'
import { createCookieWithTokenAndOriginUrl, fromPayloadToUserMapper } from 'auth/utils'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'common/hooks'
import { ActionButton } from 'common/lib'
import Button from 'common/lib/button/Button'
import { setInitialIframeState } from 'iframe/store'
import { jwtDecode } from 'jwt-decode'
import { auth, ROUTES } from 'services'

import UserAccordionItem from './UserAccordionItem'

import classes from './menu.module.css'

// const APPS_URL = [
//   'http://localhost:5174/',
//   'http://localhost:5175/',
//   'http://localhost:5174/',
//   'http://localhost:5175/',
// ]

interface IProps {}

// eslint-disable-next-line no-empty-pattern
function UserMenu({}: IProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { mutate: setApiUser, isPending } = useSetUserMutation(user?.userId || '')
  const [value, setValue] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const isMobileView = useMediaQuery('(max-width: 768px)')

  const clients = useMemo(() => {
    const ls = localStorage.getItem('clients')

    if (ls) {
      return JSON.parse(ls)
    }

    return []
  }, [])
  const applications = useMemo(() => {
    const ls = localStorage.getItem('applications')

    if (ls) {
      return JSON.parse(ls).map((e: { name: string }) => ({
        ...e,
        name: t(`apps.${e.name.toLowerCase().replace(/ /g, '-')}`),
      }))
    }

    return []
  }, [t])

  const sites = useMemo(() => {
    const ls = localStorage.getItem('sites')

    if (ls) {
      return JSON.parse(ls)
    }

    return []
  }, [])
  const clientId = localStorage.getItem('client_id')
  const applicationId = localStorage.getItem('application_id')
  const siteId = localStorage.getItem('site_id')

  const handleCloseClick = () => {
    setIsOpen(false)
  }

  const handleChangeClientClick = (id: string) => {
    auth.handleChangeClientClick()
    localStorage.setItem('client_id', id)
    dispatch(clearUserClientId())
    dispatch(clearUserApplicationid())
    dispatch(clearUserSiteId())
    dispatch(setInitialIframeState())
    dispatch(setLoginStep({ step: AuthLoginStep.SELECT_APPLICATION }))
    navigate(ROUTES.login())
  }

  const handleChangeApplicationClick = (id: string, url: string | null, name: string) => {
    auth.handleChangeApplicationClick()
    localStorage.setItem('application_id', id)
    localStorage.setItem('application_name', name)

    if (url) {
      localStorage.setItem('application_url', url)
    }

    dispatch(clearUserApplicationid())
    dispatch(clearUserSiteId())
    dispatch(setInitialIframeState())
    dispatch(setLoginStep({ step: AuthLoginStep.SELECT_SITE }))
    navigate(ROUTES.login())
  }

  const handleChangeSiteClick = (id: string) => {
    localStorage.setItem('site_id', id)
    setApiUser(
      { clientId: clientId || '', applicationId: applicationId || '', siteId: id },
      {
        onSuccess: ({ data }) => {
          localStorage.setItem('access_token', data.access_token)
          const user = jwtDecode(data.access_token) as TJwtPayload<IAuthorizedUser>
          createCookieWithTokenAndOriginUrl(data.access_token)
          dispatch(setUser({ user: fromPayloadToUserMapper(user) }))
          handleCloseClick()
        },
      },
    )
  }

  const handleLogoutClick = useCallback(() => {
    auth.handleLogoutClick()
    dispatch(setInitialIframeState())
    dispatch(setInitialAuthState())
    navigate('/login')
  }, [dispatch, navigate])

  return (
    <div ref={ref}>
      <Menu
        classNames={{
          dropdown:
            '!w-[605px] !max-w-[100%] !sm-max-w-[40%] p-6 rounded-none border-none !top-0 !right-0 flex flex-col',
        }}
        onChange={setIsOpen}
        opened={isOpen}
        styles={{
          dropdown: {
            left: 'unset',
            height: '100%',
          },
        }}
        withinPortal={false}
      >
        <Menu.Target>
          <div className={'flex items-center gap-2 cursor-pointer'}>
            {!isMobileView && (
              <Text c={'white'} className={'text-[16px] font-light'}>
                {user?.displayName || ''}
              </Text>
            )}

            <Avatar
              className={'!p-0'}
              classNames={{ root: 'bg-[#082E9D]', placeholder: 'font-medium' }}
              color={'white'}
              name={user?.displayName || ''}
              size={'38px'}
            />

            <IconChevronDown color={'white'} size={22} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <div className={'gap-4 flex items-center justify-between'}>
            <div className={'gap-4 flex items-center'}>
              <Avatar
                className={'!p-0'}
                classNames={{ root: 'bg-[#082E9D]', placeholder: 'font-medium' }}
                color={'white'}
                name={user?.displayName || ''}
                size={'38px'}
              />
              <Text c={'black'} className={'text-[18px] font-medium'}>
                {user?.displayName || ''}
              </Text>
            </div>
            <ActionButton
              classNames={{
                icon: 'duration-100 text-black',
              }}
              icon={<IconX />}
              onClick={() => setIsOpen(false)}
              variant={'transparent'}
            />
          </div>
          <Divider className={'my-4'} color={'#A5A5A5'} size={'2px'} />
          <div className={'flex flex-col justify-between gap-4 grow'}>
            <div>
              <Accordion
                chevron={<IconChevronDown size={24} />}
                classNames={{
                  root: 'flex flex-col gap-4',
                  control: classes.accordionControl,
                  item: '!m-0 border-0',
                  content: clsx('p-0', classes.accordionContent),
                }}
                onChange={setValue}
                value={value}
                variant={'separated'}
              >
                <UserAccordionItem
                  activeId={clientId || ''}
                  icon={<IconBuilding size={22} />}
                  isDisabled={isPending}
                  label={t('user.switchClient')}
                  list={clients || []}
                  mainIcon={<IconBuildings size={22} />}
                  name={'clients'}
                  onClick={handleChangeClientClick}
                  subtitle={t('user.selectClientInfoText')}
                />
                <UserAccordionItem
                  activeId={applicationId || ''}
                  icon={<IconAtom2 size={22} />}
                  isDisabled={isPending}
                  label={t('user.switchApplication')}
                  list={applications || []}
                  mainIcon={<IconAtom size={22} />}
                  name={'applications'}
                  onClick={handleChangeApplicationClick}
                  subtitle={t('user.selectApplicationInfoText')}
                />
                <UserAccordionItem
                  activeId={siteId || ''}
                  isDisabled={isPending}
                  label={t('user.switchSite')}
                  list={sites || []}
                  mainIcon={<IconSitemap size={22} />}
                  name={'sites'}
                  onClick={handleChangeSiteClick}
                />
              </Accordion>

              <div className={' mt-4 flex justify-center'}>
                <Button
                  leftSection={<IconUser size={24} />}
                  onClick={() => {
                    handleCloseClick()
                    navigate(ROUTES.settings())
                  }}
                  size={'large'}
                  variant={'outlined'}
                >
                  {t('user.userProfile')}
                </Button>
              </div>
            </div>
            <div className={'mb-8 flex justify-center'}>
              <Button
                classNames={{
                  root: 'bg-red-600 hover:bg-red-700',
                }}
                leftSection={<IconLogin2 size={24} />}
                onClick={handleLogoutClick}
                size={'large'}
              >
                {t('auth.control.logout')}
              </Button>
            </div>
          </div>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}

export default forwardRef(UserMenu)
