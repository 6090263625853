import { ReactNode } from 'react'
import { Accordion, Button, Text } from '@mantine/core'
import clsx from 'clsx'

import classes from './menu.module.css'

interface IList {
  id: string
  name: string
  url?: string
}

interface IProps {
  activeId: string
  icon?: ReactNode
  mainIcon?: ReactNode
  name: string
  label: string
  subtitle?: string
  list: IList[]
  isDisabled: boolean
  onClick: (id: string, url: string | null, name: string) => void
}

export default function UserAccordionItem({
  activeId,
  name,
  label,
  list,
  isDisabled,
  onClick,
  mainIcon,
  icon,
  subtitle,
}: IProps) {

  // const isActive = useCallback((id: string) => activeId === id, [activeId]) 

  if (list.length > 1) {
    return (
      <Accordion.Item value={name}>
        <Accordion.Control
          className={'border-solid border-2 border-[#C5C7D0] bg-white rounded-[6px]'}
          classNames={{ label: 'flex items-center gap-2', chevron: 'w-[24px]' }}
          disabled={isDisabled}
        >
          {mainIcon}
          <Text className={'font-normal text-[18px]'}>{label}</Text>
        </Accordion.Control>
        <Accordion.Panel
          className={
            'p-4 pb-0 border-solid border-t-0 border-2 border-[#C5C7D0] rounded-b-[6px]'
          }
        >
          <Text className={'mb-2 text-gray-600 text-[14px]'}>{subtitle}</Text>
          {list.map((item) => (
            <Button
              className={clsx(
                'mb-4 w-full rounded-[6px] border-1',
                activeId === item.id ? classes.itemButtonActive : classes.itemButton,
              )}
              classNames={{
                root: 'h-[auto] py-2',
                label: 'w-full flex items-center gap-2 ',
              }}
              disabled={isDisabled}
              key={item.id}
              onClick={() => onClick(item.id, item?.url || null, item.name)}
              radius={0}
            >
              {icon}
              <Text className={'text-[18px]'}>{item.name}</Text>
              {/* {item?.url && <Text className={'text-sm opacity-80'}>{item.url}</Text>} */}
              {/* {isActive(item.id) && (
                <Pill
                  classNames={{
                    root: clsx('mt-0.5', classes.menuButtonActiveText),
                    label: 'text-xs uppercase',
                  }}
                >
                  {t('common.current')}
                </Pill>
              )} */}
            </Button>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    )
  }

  return null
}
